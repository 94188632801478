import { Flex, Image, useTheme } from "@aws-amplify/ui-react";

export function SignInFooter() {
    const { tokens } = useTheme();

    return (
        <Flex justifyContent="center" padding={`${tokens.space.xl} 0 ${tokens.space.medium} `}>
            <Image className="img-acreditacion" src="videoteca_imagen acreditacion.svg"></Image>
        </Flex>
    );
}
