import React from 'react'
import SideNavigation from "@awsui/components-react/side-navigation";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from '@awsui/components-react';
import { Button } from '@awsui/components-react';

export const SideNavigationCustom = () => {
    const location = useLocation();
    let navigate = useNavigate();

    function onFollowHandler(event) {
        if (!event.detail.external) {
            event.preventDefault();
            navigate(event.detail.href);
        }
    }

    return (
        <>
            <SideNavigation
                activeHref={location.pathname}
                header={{ href: "/", text: "Cargador de Ulagos" }}
                onFollow={onFollowHandler}
                backgroundColor="brand.primary.80" color="white"
                items={[
                    { type: "link", text: "Subir Archivo", href: "/" },
                    { type: "link", text: "Listar", href: "/list" },
                    { type: "divider" },
                ]}
            />

            <div className='div-awsui'>
                <div id="section" style={{paddingBottom: "20px"}}>
                    <Link
                        className='link-awsui'
                        href='https://www.ulagos.cl/'
                        external='true'
                    >
                        Sitio web de Ulagos
                    </Link>
                    <Button className='button_awsui_external button_awsui_external_lagoscl'
                        href='https://www.ulagos.cl/' target="_blank" style={{marginBottom: "20px"}}>
                        <img src='wwwulagoscl.svg' width='165px' height='20px'></img>
                    </Button>
                </div>
                <div id="section">
                    <Link
                        className='link-awsui'
                        href='https://www.ulagosvirtual.cl/'
                        external='true'
                    >
                        Plataforma web de Ulagos Virtual
                    </Link>
                    <Button className='button_awsui_external button_awsui_external_lagosvirtual'
                        href='https://www.ulagosvirtual.cl/' target="_blank">
                        <img src='ulagos_virtual.svg' width='165px' height='20px'></img>
                    </Button>
                </div>
            </div>
            
            <ul className="awsui_list_l0dv0_bxnu6_135 awsui_list-variant-root_l0dv0_bxnu6_145" style={{marginBottom: "0px"}}>
            <li className="awsui_list-item_l0dv0_bxnu6_155">
                <hr className="awsui_divider_l0dv0_bxnu6_227 awsui_divider-default_l0dv0_bxnu6_232"></hr>
                </li>
            </ul>
            <div className='div-awsui'>
                <div id="title" style={{color: "#776f69", textAlign:"left"}}><b>Ayuda video tutorial</b></div>
                <br />
                <Button className='button_awsui_external button_upload_resource' style={{textAlign: "center", color: "white"}}
                    href='https://www.youtube.com/watch?v=KQSB-uLTmO4' target="_blank" >
                    Subir recursos
                </Button>
                <br />
                <br />
                <Button className='button_awsui_external button_publish_resource'
                    href='https://www.youtube.com/watch?v=FRVrbHqraU0' target="_blank" style={{textAlign: "center", color: "white"}}>
                    Publicar recursos
                </Button>
            </div>
        </>
    );
};