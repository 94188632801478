/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_content_delivery_bucket": "ulagosuploadvideos-20220530173645-hostingbucket-ulagos",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d4x71o0uc5qt5.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-east-1:6e680269-bd28-4dbb-b0b7-c498ab62de7f",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_xuqllbGN0",
    "aws_user_pools_web_client_id": "69j8916evth3h4ceg0dd6bhftc",
    "oauth": {
        "domain": "s3uploader-ulagos.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://videoteca.ulagos.cl/,https://localhost:3005/",
        "redirectSignOut": "https://videoteca.ulagos.cl/,https://localhost:3005/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "ulagosuploadvideos89d9252ddc42445c87658e19abf1f175352-ulagos",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
