import React, { useState, useRef, useEffect } from "react";
import "@aws-amplify/ui-react/styles.css";
import "../styles/App.css";
import FormField from "@awsui/components-react/form-field";
import Alert from "@awsui/components-react/alert";
import Container from "@awsui/components-react/container";
import Header from "@awsui/components-react/header";
import Button from "@awsui/components-react/button";
import TokenGroup from "@awsui/components-react/token-group";
import SpaceBetween from "@awsui/components-react/space-between";
import ProgressBar from "@awsui/components-react/progress-bar";
import { Storage } from "aws-amplify";
import Input from "@awsui/components-react/input";
import { makeStyles } from "@mui/styles";

const FILE_1GB_SIZE = 1073741824

const useStyles = makeStyles({
  nameInput: {
    width: "35%",
  },
});

function formatBytes(a, b = 2, k = 1024) {
  let d = Math.floor(Math.log(a) / Math.log(k));
  return 0 === a
    ? "0 Bytes"
    : parseFloat((a / Math.pow(k, d)).toFixed(Math.max(0, b))) +
    " " +
    ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d];
}

const Uploader = () => {
  const hiddenFileInput = useRef(null);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [uploadList, setUploadList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [historyList, setHistoryList] = useState([]);
  const [historyCount, setHistoryCount] = useState(0);
  const classes = useStyles();
  const [videoName, setVideoName] = useState("");
  const [alertText, setAlertText] = useState("");
  const [alertHeader, setAlertHeader] = useState("");
  const [uploadCompleted, setUploadCompleted] = useState([]);

  const handleClick = () => {
    hiddenFileInput.current.value = ""; // This avoids errors when selecting the same files multiple times
    hiddenFileInput.current.click();
  };

  const handleChange = (e) => {
    let validFiles = true

    e.preventDefault();
    let i, tempUploadList = [];

    for (i = 0; i < e.target.files.length; i++) {
      if (e.target.files[i].size > FILE_1GB_SIZE) {
        validFiles = false
      } else {
        tempUploadList.push({
          label: e.target.files[i].name,
          labelTag: formatBytes(e.target.files[i].size),
          description: "File type: " + e.target.files[i].type,
          icon: "file",
          id: i,
        });
      }
    }

    if (!validFiles) {
      setAlertHeader(`El tamaño de al menos un archivo supera el máximo permitido.`)
      setAlertText('El archivo debe pesar menos de un 1 GB.')
      setVisibleAlert(true)
    } else {
      setUploadList(tempUploadList);
      setFileList(e.target.files);
      setDisableButton(false)
      setVisibleAlert(false)
    }
  };

  function progressBarFactory(fileObject) {
    let localHistory = historyList;
    const id = localHistory.length;
    localHistory.push({
      id: id,
      percentage: 0,
      filename: fileObject.name,
      filetype: fileObject.type,
      filesize: formatBytes(fileObject.size),
      status: "in-progress",
    });
    setHistoryList(localHistory);
    return (progress) => {
      let tempHistory = historyList.slice();
      const percentage = Math.round((progress.loaded / progress.total) * 100);
      tempHistory[id].percentage = percentage;
      if (percentage === 100) {
        tempHistory[id]["status"] = "success";
      }
      setHistoryList(tempHistory);
    };
  }

  const setFileName = (data) => {
    let i,
      progressBar = [];
    for (i = 0; i < data.filesToUpload.length; i++) {
      // If the user has removed some items from the Upload list, we need to correctly reference the file
      const id = data.filesToUpload[i].id;
      progressBar.push(progressBarFactory(fileList[id]));
      setHistoryCount(historyCount + 1);
      setUploadCompleted(
        Storage.put(
          data.s3FileName.charAt(0).toUpperCase() +
          data.s3FileName.slice(1) +
          "_" +
          fileList[id].name,
          fileList[id],
          {
            progressCallback: progressBar[i],
            level: "protected",
          }
        ).then((result) => {
          // Trying to remove items from the upload list as they complete. Maybe not work correctly
          // setUploadList(uploadList.filter(item => item.label !== result.key));
          console.log(`Completed the upload of ${result.key}`);
        })
      );
    }
  };

  const handleUpload = () => {
    if (uploadList.length === 0) {
      setAlertHeader('Sin archivos seleccionados')
      setAlertText('Debe seleccionar al menos 1 archivo.')
      setVisibleAlert(true)
      return
    }
    if (videoName === '') {
      setAlertHeader('Clase/s sin nombre')
      setAlertText('Debe completar el campo: NOMBRE CLASE.')
      setVisibleAlert(true)
      return
    }
    console.log("Uploading files to S3");
    let data = {
      filesToUpload: uploadList,
      s3FileName: videoName,
    };
    setFileName(data);
    // When you finish the loop, all items should be removed from the upload list
    Promise.all(uploadCompleted).then(() => {
      setVideoName("");
      setUploadList([]);
      setDisableButton(true)
    });
  };

  const handleDismiss = (itemIndex) => {
    setUploadList([
      ...uploadList.slice(0, itemIndex),
      ...uploadList.slice(itemIndex + 1),
    ]);
  };

  const List = ({ list }) => (
    <>
      {list.map((item) => (
        <ProgressBar
          key={item.id}
          status={item.status}
          value={item.percentage}
          variant="standalone"
          additionalInfo={item.filesize}
          description={item.filetype}
          label={item.filename}
        />
      ))}
    </>
  );

  const handleChangeVideoName = (value) => {
    setVideoName(value);
    if (uploadList.length !== 0) {
      setDisableButton(false)
    }
  };
  return (
    <SpaceBetween size="l">
      <Container
        id="s3-upload-multiple-objects"
        header={<Header variant="h2">Subir clase a la nube.</Header>}
      >
        {
          <div>
            <Alert
              onDismiss={() => {
                setVisibleAlert(false)
                setDisableButton(true)
              }}
              visible={visibleAlert}
              dismissAriaLabel="Close alert"
              dismissible
              type="error"
              header={alertHeader}
            >
              {alertText}
            </Alert>

            <SpaceBetween direction="vertical" size="xs">
              <FormField label="Nombre de clase">
                <Input
                  type="text"
                  placeholder="Nombre Clase"
                  className={classes.nameInput}
                  onChange={(e) => handleChangeVideoName(e.detail.value)}
                  value={videoName}
                />
              </FormField>

              <FormField>
                <Button
                  onClick={handleClick}
                  className='awsui_button_file'
                  iconAlign="left"
                  iconName="upload"
                >
                  Seleccionar Archivo[s]
                </Button>
                <input
                  type="file"
                  ref={hiddenFileInput}
                  onChange={handleChange}
                  style={{ display: "none" }}
                  multiple
                />
              </FormField>
              <small>Tamaño maximo permitido por archivo <b>1 GB</b></small>
              <FormField>
                <Button id="buttonupload" className="borderbutton" variant="primary" onClick={handleUpload} disabled={disableButton}>
                  Subir Clase
                </Button>
              </FormField>
            </SpaceBetween>

            <TokenGroup
              onDismiss={({ detail: { itemIndex } }) => {
                handleDismiss(itemIndex);
              }}
              items={uploadList}
              alignment="vertical"
              limit={10}
            />
          </div>
        }
      </Container>
      <Container id="history" header={<Header variant="h2">Historial</Header>}>
        <List list={historyList} />
      </Container>
    </SpaceBetween>
  );
};

export default Uploader;
