import { Heading, Image, useTheme } from "@aws-amplify/ui-react";

export function SignInHeader() {
    const { tokens } = useTheme();

    return (
        <div className="header">
            <Image className="img-sedes" src="sedes.png"></Image>
            <div>
                <Image className="img-correo" src="logo_videoteca_color.png"></Image>
            </div>
            <div className="box-correo">
                <span className="correo">INGRESE CON SU CORREO INSTITUCIONAL</span>
            </div>
        </div>
    );
}
