import React, { useEffect, useState } from "react";
import "@aws-amplify/ui-react/styles.css";
import "../styles/App.css";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Button from "@awsui/components-react/button";
import { Storage } from "aws-amplify";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContentText-root": {
    fontSize: 15,
    fontFamily:
      'var(--font-family-base-b2oeeo, "Noto Sans", "Helvetica Neue", Roboto, Arial, sans-serif)',
  },
  "& .MuiDialogTitle-root": {
    fontSize: 20,
    fontFamily:
      'var(--font-family-base-b2oeeo, "Noto Sans", "Helvetica Neue", Roboto, Arial, sans-serif)',
  },
}));
const useStyles = makeStyles({
  centerSpinner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

const DialogAlert = ({ open, selected, setOpen, setFile, userId, file, setSelected, todeleteAll }) => {
  const classes = useStyles();
  const [spinner, setSpinner] = useState(false);

  const deleteObject = (key) => {
    Storage.remove(key, { level: 'protected' })
      .then(result => {
        setFile("");
        setSpinner(false);
        setOpen(false);
        window.location.reload();
      })
      .catch(err => console.log(err));
  };

  const deleteAll = () => {
    if (todeleteAll) {
      selected.forEach((e) => {
        deleteObject(e);
        console.log(e);
      });
    } else {
      deleteObject(file)
    }

  };

  const handleClose = (toDelete) => {
    if (toDelete) {
      setSpinner(true);
      deleteAll();
      return;
    }

    setOpen(false);
    setFile("");
  };

  const separarTextoArchivo = () => {
    let i = 0;
    return selected.map((item) => {
      i++;
      return (
        <>
          {" "}
          <br /> {i}) {item}
        </>
      );
    });
  };

  const getTexto = () => {
    if (file) {
      return (
        <>
          Desea eliminar el archivo: <b>{file}</b>?
        </>
      )
    } else {
      return (
        <>
          Desea eliminar todos los archivos?
        </>
      );
    }
  }

  return (
    <>
      <StyledDialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose(false)}
      >
        <DialogTitle>{"Eliminar Clase"}</DialogTitle>
        <DialogContent>
          <DialogContentText>{getTexto()}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            loading={spinner}
            variant="primary"
            onClick={() => handleClose(true)}
          >
            Aceptar
          </Button>
          <Button loading={spinner} onClick={() => handleClose(false)}>
            Cancelar
          </Button>
        </DialogActions>
      </StyledDialog>
    </>
  );
};

export default DialogAlert;
