import React, { useEffect, useState } from "react";
import { Storage } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import "../styles/App.css";
import VideosTable from "../components/table";
import Alert from "@awsui/components-react/alert";
import Spinner from "@awsui/components-react/spinner";
import Container from "@awsui/components-react/container";
import SpaceBetween from "@awsui/components-react/space-between";
import { Auth } from "aws-amplify";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  centerSpinner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

const Videos = () => {
  const [filas, setFilas] = useState([]);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [errorList, setErrorList] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [userId, setUserId] = useState("");
  const classes = useStyles();

  useEffect(() => {
    let user = Auth.currentUserInfo().then((response) => {
      setUserId({ data: response });
    });
  }, [filas]);

  useEffect(() => {
    ListVideos();
  }, []);

  const ListVideos = () => {
    setSpinner(true);
    let list = Storage.list("", { level: "protected" })
      .then((result) => {
        if (!result.length > 0) {
          setVisibleAlert(true);
        }
        setFilas(result);
        setSpinner(false);
      })
      .catch((error) => {
        setErrorList(error);
        setVisibleAlert(true);
        console.log("error", error);
        setSpinner(false);
      });
  };

  return (
    <>
      <SpaceBetween size="l">
        <Container id="s3-upload-multiple-objects">
          {visibleAlert && (
            <>
              <Alert
                onDismiss={() => setVisibleAlert(false)}
                visible={visibleAlert}
                dismissAriaLabel="Close alert"
                dismissible
                type="error"
                header="Sin Videos Cargados"
              >
                {errorList}
              </Alert>
            </>
          )}
          {spinner && (
            <>
              {" "}
              <div className={classes.centerSpinner}>
                {" "}
                <Spinner size="large" />{" "}
              </div>{" "}
            </>
          )}
          {!spinner && filas.length > 0 && userId !== "" && (
            <>
              {" "}
              <VideosTable filas={filas} userId={userId} />{" "}
            </>
          )}
        </Container>
      </SpaceBetween>
    </>
  );
};

export default Videos;
