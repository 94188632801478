import React, { useState } from "react";
import "@aws-amplify/ui-react/styles.css";
import "./styles/App.css";
import AppLayout from "@awsui/components-react/app-layout";
import { SideNavigationCustom } from './components/SideNavigationCustom'
import TopNavigation from "@awsui/components-react/top-navigation";
import Amplify, { Auth } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import { translations } from '@aws-amplify/ui-react';
import { I18n } from 'aws-amplify';
import config from "./aws-exports";
import Uploader from "./components/uploader";
import Videos from "./components/videos";
import { SignInHeader } from "./components/Login/SignInHeader";
import { SignInFooter } from "./components/Login/SignInFooter";
import { makeStyles } from "@mui/styles";

I18n.putVocabularies(translations);
I18n.setLanguage('es');
const dict = {
  'es': {
    "Sign In with Google": "ENTRAR",
  }
};

I18n.putVocabularies(dict);

var urlsIn = config.oauth.redirectSignIn.split(",");
var urlsOut = config.oauth.redirectSignOut.split(",");
const oauth = {
  domain: config.oauth.domain,
  scope: config.oauth.scope,
  redirectSignIn: config.oauth.redirectSignIn,
  redirectSignOut: config.oauth.redirectSignOut,
  responseType: config.oauth.responseType,
};
var hasLocalhost = (hostname) =>
  Boolean(
    hostname.match(/localhost/) ||
    hostname.match(/127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}/)
  );
var hasHostname = (hostname) =>
  Boolean(hostname.includes(window.location.hostname));
var isLocalhost = hasLocalhost(window.location.hostname);
if (isLocalhost) {
  urlsIn.forEach((e) => {
    if (hasLocalhost(e)) {
      oauth.redirectSignIn = e;
    }
  });
  urlsOut.forEach((e) => {
    if (hasLocalhost(e)) {
      oauth.redirectSignOut = e;
    }
  });
} else {
  urlsIn.forEach((e) => {
    if (hasHostname(e)) {
      oauth.redirectSignIn = e;
    }
  });
  urlsOut.forEach((e) => {
    if (hasHostname(e)) {
      oauth.redirectSignOut = e;
    }
  });
}
var configUpdate = config;
configUpdate.oauth = oauth;
Amplify.configure(configUpdate);

const useStyles = makeStyles({
  center: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "15%",
    backgroundImage: "/public/videoteca_fondo_login.svg"
  },
});

const appLayoutLabels = {
  navigation: "Side navigation",
  navigationToggle: "Open side navigation",
  navigationClose: "Close side navigation",
  notifications: "Notifications",
  tools: "Help panel",
  toolsToggle: "Open help panel",
  toolsClose: "Close help panel",
};

const components = {
  SignIn: {
    Header: SignInHeader,
    Footer: SignInFooter
  },
}

function App() {
  const classes = useStyles();

  return (
    <Authenticator className={classes.center} components={components}>
      {({ signOut, user }) => Content(signOut, user)}
    </Authenticator>
  );
}

function Content(signOut, user) {
  const [navigationOpen, setNavigationOpen] = useState(true);

  const navbarItemClick = (e) => {
    console.log(e);
    if (e.detail.id === "signout") {
      Auth.signOut().then(() => {
        window.location.reload();
      });
    }
  };

  const setContent = () => {
    if (location.pathname === "/list") {
      return <Videos />;
    } else {
      return <Uploader />;
    }
  };

  return (
    <>
      <div
        id="navbar"
        style={{
          fontSize: "body-l !important",
          position: "sticky",
          top: 0,
          zIndex: 1002,
        }}
      >
        <TopNavigation
          identity={{
            href: "#",
            logo: {
              src: "videoteca_logo_videoteca_blanco.svg",
            },
          }}
          utilities={[
            {
              type: "menu-dropdown",
              text: user.signInUserSession.idToken.payload.email,
              description: user.signInUserSession.idToken.payload.email,
              iconName: "user-profile",
              onItemClick: navbarItemClick,
              items: [
                { id: "signout", text: "Sign out" },
              ],
            },
          ]}
          i18nStrings={{
            searchIconAriaLabel: "Search",
            searchDismissIconAriaLabel: "Close search",
            overflowMenuTriggerText: "More",
          }}
        />
      </div>
      <AppLayout
        content={setContent()}
        contentType="table"
        headerSelector="#navbar"
        navigation={<SideNavigationCustom />}
        navigationOpen={navigationOpen}
        onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
        ariaLabels={appLayoutLabels}
      />
    </>
  )
}

export default App;