import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import TableSortLabel from "@mui/material/TableSortLabel";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Input from "@awsui/components-react/input";
import { FormField, Alert } from "@awsui/components-react";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import { visuallyHidden } from "@mui/utils";
import DialogAlert from "../components/dialog";
import Header from "@awsui/components-react/header";
import configExports from "../aws-exports";

const useStyles = makeStyles({
  searchInput: {
    width: "30%",
  },
  paginator: {
    fontSize: 16,
    fontFamily:
      'var(--font-family-base-b2oeeo, "Noto Sans", "Helvetica Neue", Roboto, Arial, sans-serif)',
  },
  aceptButton: {
    backgroundColor: "#ff3338",
    color: "white",
  },
  cancelButton: {
    backgroundColor: "#0047c9",
    color: "white",
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#00a1c9",
    color: theme.palette.common.white,
    fontSize: 20,
    fontFamily:
      'var(--font-family-base-b2oeeo, "Noto Sans", "Helvetica Neue", Roboto, Arial, sans-serif)',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
    fontFamily:
      'var(--font-family-base-b2oeeo, "Noto Sans", "Helvetica Neue", Roboto, Arial, sans-serif)',
  },
}));

function createData(clase, nombre, key, lastModified, size, url) {
  return { clase, nombre, key, lastModified, size, url };
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const VideosTable = ({ filas, userId }) => {
  const [selected, setSelected] = useState([]);
  const [rows, setRows] = useState([]);
  const [filasParsed, setFilasParsed] = useState([]);
  const [numSelected, setNumSelected] = useState();
  const [todeleteAll, setDeleteAll] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [alertUrl, setAlertUrl] = useState(false);
  const [file, setFile] = useState("");
  const [toDelete, setToDelete] = useState("");
  const classes = useStyles();

  useEffect(() => {
    setRows(parseData(filas));
    setFilasParsed(parseData(filas));
  }, [filas]);

  const parseData = (filas) => {
    return filas.map((o) => {
      let arrNombres = o.key.split("_");
      let clase = arrNombres[0];
      let nombre = arrNombres[1];
      return createData(
        clase,
        nombre,
        o.key,
        o.lastModified.getDate() +
        "/" +
        o.lastModified.getMonth() +
        "/" +
        o.lastModified.getFullYear(),
        o.size
      );
    });
  };

  const handleDeleteClick = (key) => {
    if (!selected.find(element => element === key)) {
      let newArray = selected.concat(key);
      setSelected(newArray);
      console.log(newArray);
    }
    setFile(key);
    setOpen(true);
  };

  const getUrl = async (key) => {
    setAlertUrl(true);
    await navigator.clipboard.writeText(getUrlObject(key));
  };

  const getUrlObject = (key) => {
    return `https://${configExports.aws_user_files_s3_bucket}.s3.amazonaws.com/protected/${userId.data.id}/${key}`;
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.key);
      console.log(newSelecteds)
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleSelect = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const deleteAll = () => {
    setDeleteAll(true)
    setOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleSearch = (value) => {
    if (value === "") {
      //si es blanco, setea el set original
      setRows(filasParsed);
      setSearch(value);
      return;
    }
    setSearch(value);
    let result = filasParsed.filter((item) => {
      return Object.values(item)
        .join("")
        .toLowerCase()
        .includes(value.toLowerCase());
    });
    setRows(result);
  };

  return (
    <>
      <Paper>
        <Alert
          header={"Link copiado"}
          visible={alertUrl}
          dismissible={true}
          onDismiss={() => setAlertUrl(false)}
        />
        <DialogAlert
          open={open}
          setOpen={setOpen}
          setFile={setFile}
          userId={userId}
          selected={selected}
          file={file}
          setSelected={setSelected}
          todeleteAll={todeleteAll}
        />
        <Header variant="h2">Clases.</Header>
        <FormField>
          <Input
            value={search}
            onChange={(event) => handleSearch(event.detail.value)}
            type="search"
            placeholder="Buscar videos"
            className={classes.searchInput}
          />
        </FormField>
        <br></br>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {selected.length > 0 ? (
                  <IconButton style={{ color: '#ff9d66' }}>
                    <DeleteIcon
                      fontSize="large"
                      onClick={() => deleteAll()} />
                  </IconButton>
                ) : (
                  <></>
                )}
              </TableRow>

              <TableRow>
                <StyledTableCell padding="checkbox" className="colortable-header">
                  <Checkbox
                    size="large"
                    style={{ color: 'white' }}
                    indeterminate={
                      selected.length > 0 && selected.length < rows.length
                    }
                    checked={rows.length > 0 && selected.length === rows.length}
                    onChange={(event) => handleSelectAll(event)}
                    inputProps={{
                      "aria-label": "select all desserts",
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell
                  className="colortable-header"
                  padding={"none"}
                  sortDirection={orderBy === "key" ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === "key"}
                    direction={orderBy === "key" ? order : "asc"}
                    onClick={(e) => handleRequestSort(e, "key")}
                  >
                    {"Clase"}
                    {orderBy === "key" ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell
                  className="colortable-header"
                  padding={"none"}
                  sortDirection={orderBy === "key" ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === "key"}
                    direction={orderBy === "key" ? order : "asc"}
                    onClick={(e) => handleRequestSort(e, "key")}
                  >
                    {"Archivo"}
                    {orderBy === "key" ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell className="colortable-header" align="right">Fecha</StyledTableCell>
                <StyledTableCell className="colortable-header" align="right">Tamaño</StyledTableCell>
                <StyledTableCell className="colortable-header" align="right">Acciones</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.key);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow key={row.key}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          style={{ color: '#ff9d66' }}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            "color:": "white"
                          }}
                          onClick={(event) => handleSelect(event, row.key)}
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.key}
                          selected={isItemSelected}
                          size="large"
                        />
                      </TableCell>

                      <StyledTableCell component="th" scope="row">
                        {row.clase}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.nombre}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.lastModified}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.size}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <Tooltip title="Copiar Link" arrow>
                          <IconButton
                            style={{ color: '#ff9d66' }}
                            onClick={() => getUrl(row.key)}>
                            <ContentCopyIcon fontSize="large" />
                          </IconButton>
                        </Tooltip>
                        <IconButton
                          style={{ color: '#ff9d66' }}
                          onClick={() => handleDeleteClick(row.key)}>
                          <DeleteIcon fontSize="large" />
                        </IconButton>
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={"Videos por página"}
          labelDisplayedRows={({ from, to, count }) => {
            return '' + from + '-' + to + ' de ' + count
          }}
          sx={{
            ".MuiTablePagination-toolbar": {
              fontSize: 16,
              fontFamily:
                'var(--font-family-base-b2oeeo, "Noto Sans", "Helvetica Neue", Roboto, Arial, sans-serif)',
            },
            ".MuiTablePagination-selectLabel, .MuiTablePagination-input, .MuiTablePagination-menuItem":
            {
              fontSize: 16,
              fontFamily:
                'var(--font-family-base-b2oeeo, "Noto Sans", "Helvetica Neue", Roboto, Arial, sans-serif)',
            },
            ".MuiTablePagination-displayedRows,": {
              fontSize: 16,
              fontFamily:
                'var(--font-family-base-b2oeeo, "Noto Sans", "Helvetica Neue", Roboto, Arial, sans-serif)',
            },
            ".MuiTablePagination-select,": {
              fontSize: 16,
              fontFamily:
                'var(--font-family-base-b2oeeo, "Noto Sans", "Helvetica Neue", Roboto, Arial, sans-serif)',
            },
            ".MuiTablePagination-menuItem": {
              fontSize: 26,
              fontFamily:
                'var(--font-family-base-b2oeeo, "Noto Sans", "Helvetica Neue", Roboto, Arial, sans-serif)',
            },
          }}
        />
      </Paper>
    </>
  );
};
export default VideosTable;
